import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyB4FXl2V1Fkl2tJBx6yTQeidrsUAy8gZzc",
    authDomain: "sp1ru-387320.firebaseapp.com",
    databaseURL: "https://sp1ru-387320-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "sp1ru-387320",
    storageBucket: "sp1ru-387320.appspot.com",
    messagingSenderId: "152785481960",
    appId: "1:152785481960:web:414b4b877f41744ab9995d",
    measurementId: "G-K0M6CL6B0F"
  };

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db };