import React, { useEffect, useState } from 'react';
import './App.css';
import { db } from './firebase';
import { doc, getDoc, setDoc, updateDoc, increment } from 'firebase/firestore';

function App() {
    const [countdown, setCountdown] = useState('');
    const [requestCount, setRequestCount] = useState(null);

    const fetchRequestCount = async () => {
        try {
            const docRef = doc(db, 'requests', 'requestCount');
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                await updateDoc(docRef, {
                    count: increment(1)
                });
                const updatedDoc = await getDoc(docRef); 
                setRequestCount(`Requests sent: ${updatedDoc.data().count}`);
            } else {
                await setDoc(docRef, { count: 1 });
                setRequestCount("Requests sent: 1");
            }
        } catch (error) {
            console.error("Ошибка получения данных:", error);
            setRequestCount("Ошибка загрузки данных");
        }
    };

    const updateCountdown = () => {
        const now = new Date();
        const newYear = new Date(now.getFullYear() + 1, 0, 1);
        const diff = newYear - now;

        const days = Math.floor(diff / (1000 * 60 * 60 * 24));
        const hours = Math.floor((diff % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((diff % (1000 * 60)) / 1000);

        setCountdown(`${days} Days ${hours} Hours ${minutes} Minutes ${seconds} Seconds`);
    };

    const createSnowflake = () => {
        const snowflake = document.createElement('div');
        snowflake.className = 'snowflake';
        snowflake.innerHTML = '❄';
        snowflake.style.left = Math.random() * 100 + 'vw';
        snowflake.style.fontSize = Math.random() * 10 + 15 + 'px';
        snowflake.style.animationDuration = Math.random() * 5 + 3 + 's';
        document.body.appendChild(snowflake);

        snowflake.addEventListener('animationend', () => {
            snowflake.remove();
        });
    };

    useEffect(() => {
        fetchRequestCount();
        const countdownInterval = setInterval(updateCountdown, 1000);
        const snowflakeInterval = setInterval(createSnowflake, 250);

        return () => {
            clearInterval(countdownInterval);
            clearInterval(snowflakeInterval);
        };
    }, []);

    return (
        <div className="App">
            <h1>Soon...</h1>
            <h2>Until New Year:</h2>
            <div className="countdown">{countdown}</div>
            <div className="request-counter">
                {requestCount === null ? (
                    <div className="spinner"></div>
                ) : (
                    requestCount
                )}
            </div>
        </div>
    );
}

export default App;